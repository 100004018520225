import React from 'react';
import '../../styles/contactus.css';

const ContactUs = () => {
    return (
        <section id="contact" className="contact_section">
            <div className="container">
                <div className="contact_wrapper">
                    <div className="contact_content">
                        <h6 className="subtitle">Get in Touch</h6>
                        <h2>Contact Us</h2>
                        <p className="description">We would love to hear from you. Whether you have a question about features, pricing, or anything else, our team is ready to answer all your questions.</p>
                        
                        <form className="contact_form">
                            <div className="form_group">
                                <input type="text" placeholder="Your Name" required />
                            </div>
                            <div className="form_group">
                                <input type="email" placeholder="Your Email" required />
                            </div>
                            <div className="form_group">
                                <textarea placeholder="Your Message" required></textarea>
                            </div>
                            <button type="submit" className="submit_btn">Send Message</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
