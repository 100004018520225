import React from 'react';
import '../../styles/about.css';


const chooseData = [
    {
        icon: 'ri-pencil-ruler-2-fill',
        title: 'Branding',
        desc: 'Compelling brand narratives, identities, and campaigns with a significant impact.',
    },
    {
        icon: 'ri-shield-check-fill',
        title: 'Creatives',
        desc: 'Striking, attention-grabbing creative design engineered to boost conversions.',
    },
    {
        icon: 'ri-pencil-ruler-fill',
        title: 'Content',
        desc: 'Engaging Video and Photography that Inspires and Prompts Actions. '
    },
    {
        icon: 'ri-bar-chart-grouped-fill',
        title: 'Paid Social',
        desc: 'Elevated intent leads to an increased conversion rate, and that is precisely the focus of Google Ads.'
    },
    {
        icon: 'ri-pencil-ruler-fill',
        title: 'Paid Search',
        desc: 'Paid Search Marketing Focused on ROI, Designed to Convert New Customers and Expand Your Brand. '
    },
    {
        icon: 'ri-pencil-ruler-fill',
        title: 'Search Engine Optimization',
        desc: 'Increase Your Website traffic by Securing top Rankings On Search Engines. '
    }, 
    {
        icon: 'ri-pencil-ruler-fill',
        title: 'Social Media Marketing',
        desc: 'Expand Your Community of Devoted Brand Supporters Throught the Influence of Social Media. '
    },   
];

const About = () => (
    <section id="about">
        <div className="container">
            <div className="about_wrapper">
                <div className="about_content">
                    <h6 className="subtitle">Why Choose Us</h6>
                    <h2>
                        We transform brand culture into brand advocacy by leveraging digital
                        tools to establish a dedicated community of loyal customers.
                    </h2>
                    <h2 className="highlight">Choose From Our Tailored Solutions</h2>
                    
                    <div className="choose_wrapper">
                        {
                            chooseData.map((item, index) => 
                                <div className="choose_item" key={index}>
                                    <span className="choose_us-icon">
                                        <i className={item.icon}></i>
                                    </span>
                                    <h4 className="choose_title">{item.title}</h4>
                                    <p className="description">{item.desc}</p>
                                </div>
                            )
                        }
                    </div>
                </div>
                
            </div>
        </div>
    </section>
);

export default About;