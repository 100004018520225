import React from 'react'
import '../../styles/counter.css'

const counterData = [
    
    {
        number: "98%",
        title: "Projects Completed"
    },
    {
        number: "25+",
        title: "Awards & Recognition"
    },
    {
        number: "50+",
        title: "Professional Teams"
    }
]
const Counter = () => {
  return <section className="counter" id="projects">
    <div className="container">
       <div className="counter_wrapper">
        {
            counterData.map((item, index) => {
                return <div className="counter_item" key={index}>
                    <h3 className="counter_number">{item.number}</h3>
                    <h4 className="counter_title">{item.title}</h4>
                </div>
            })
        }
        <div className="counter_item">
            <h3 className="counter_number">2K+</h3>
            <h4 className="counter_title">Happy Clients</h4>
        </div>
        </div> 
    </div>
  </section> 
}
export default Counter
