import React from 'react'
import './footer.css'

const quickLinks01 = [
    {
        path:'#',
        display:'Marketing'
    },
    {
        path:'#',
        display:'Analytics'
    },
    {
        path:'#',
        display:'Commerce'
    },
];

const quickLinks02 = [
    {
        path:'#',
        display:'Pricing'
    },
    {
        path:'#',
        display:'Documentation'
    },
    {
        path:'#',
        display:'Guides'
    },
];

const quickLinks03 = [
    {
        path:'#',
        display:'About Us'
    },
    {
        path:'#',
        display:'Job'
    },
    {
        path:'#',
        display:'Blogs'
    },
]




const Footer = () => {

    const year = new Date().getFullYear()
    
  return <footer className="footer">
    <div className="container">
        <div className="footer_wrapper">
            <div className="footer_logo">
                <p className="footer_title">Rexium It Solutions</p >
                <p className="small_text">The Growth Marketing Agency</p>

                <p className="small_text description">We are a creative digital agency that specializes in creating innovative solutions for brands of all sizes. Our team of experts is dedicated to helping businesses grow through cutting-edge digital marketing strategies.</p>
            </div>

          <div className="footer_quick-links">
            <h3 className="quick_links-title">Solutions</h3>
            <ul className="quick_links">
                {
                  quickLinks01.map((item, index) => (
                    <li className='quick_links-item' key={index}><a href={item.path}>{item.display}</a></li>
                  ))
                }
            </ul>
          </div>  
          
          <div className="footer_quick-links">
            <h3 className="quick_links-title">Support</h3>
            <ul className="quick_links">
                {
                  quickLinks02.map((item, index) => (
                    <li className='quick_links-item' key={index}><a href={item.path}>{item.display}</a></li>
                  ))
                }
            </ul>
          </div>  <div className="footer_quick-links">
            <h3 className="quick_links-title">Company</h3>
            <ul className="quick_links">
                {
                  quickLinks03.map((item, index) => (
                    <li className='quick_links-item' key={index}><a href={item.path}>{item.display}</a></li>
                  ))
                }
            </ul>
          </div>  
        </div>

        <p className="copyright">Copyright © {year} Developed by Rexium IT Solutions. All Rights Reserved</p>
    </div>
  </footer>
}

export default Footer
