import React, { useState,useEffect } from 'react';
import './App.css';

import Header from './components/Header/Header';
import Counter from './components/UI/Counter';
import Hero from './components/UI/Hero';
import Services from './components/UI/Services';
import About from './components/UI/About';
import Testimonial from './components/UI/Testimonial';
import Newsletter from './components/UI/Newsletter';
import Footer from './components/Footer/Footer';
import ContactUs from './components/UI/contactus';
function App() {

  const [theme, setTheme] = useState('');
  const toggleTheme = () => {
    theme === '' ? setTheme('light-theme') : setTheme('');
  }

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return <>
  <Header theme={theme} toggleTheme={toggleTheme} />
  <Hero theme={theme} />
  <Counter />
  <Services />
  <About />
  <Testimonial />
  <ContactUs />
  <Newsletter />
  <Footer />
  </>
}

export default App;
