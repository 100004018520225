import React, { useState, useEffect } from 'react';
import '../styles/RotatingText.css';

const RotatingText = () => {
  const phrases = [
    "SEO",
    "Social Media Marketing",
    "Web Development",
    "Pay Per Click (SEM)",
    "Design & Branding",
    "Content Marketing",
    "Influencer Marketing"
  ];

  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, 4000); // Change phrase every 4 seconds (2s typing + 2s visibility)

    return () => clearInterval(intervalId);
  }, [phrases.length]);

  return (
    <div className="highlight">
      <span className="text">{phrases[currentPhraseIndex]}</span>
    </div>
  );
};

export default RotatingText;
