import React from 'react';
import '../../styles/hero.css';
import heroDarkImg from '../../images/hero-img.png';
import RotatingText from '../RotatingText';
import lightImg from '../../images/light-hero-bg.png';

const Hero = ({theme}) => {
    return (
        <section className="hero_section">
            <div className="container">
                <div className="hero_wrapper">
                    <div className="hero_content">
                        <div>
                            <h2>High Performance Growth Agency</h2>
                            <h2 className='heading hero_h2'>A Full Suite Digital Marketing Agency Offering</h2>
                            <div className="highlight">
                                <RotatingText />
                            </div>
                        </div>
                        <div className="hero_btns">
                            <button className='primary_btn'>Get Started</button>
                            <button className='secondary_btn'>Contact Us</button>
                        </div>
                    </div>
                    <div className="hero_img">
                        <img src={ theme === 'light-theme' ? lightImg : heroDarkImg} alt="hero-img" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
