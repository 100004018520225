import React from 'react'
import '../../styles/services.css'

const serviceData = [
    {
        icon: 'ri-code-s-slash-fill',
        title: 'Web Development',
        desc: 'High Performance Website Development to Drive Sales for you Business.'
    },
    {
        icon: 'ri-medal-2-fill',
        title: 'Performance Marketing',
        desc: 'Exceptional Marketing Programs to Deliver 360 degree Growth to you Business.'
    },
    {
        icon: 'ri-seo-line',
        title: 'SEO Booster',
        desc: 'Powerful SEO techniques to drive traffic and Rank your Business on Top of Search Results.'
    },
    {
        icon: 'ri-meta-line',
        title: 'Meta Ads',
        desc: 'Whether you embrace or dislike it, Facebook and Instagram stand as the primary platforms for the majority of B2C products and services. With over a billion active users, these platforms offer a significant potential for generating substantial sales.'
    },
    {
        icon: 'ri-linkedin-line',
        title: 'LinkedIn Ads',
        desc: 'The ultimate goal in B2B marketing. When executed effectively, you can effortlessly attract top-notch leads from multinational corporations to small and medium enterprises. Its a low-cost strategy with a high return on investment, no doubt.'
    },
    {
        icon: 'ri-wordpress-line',
        title: 'Wordpress Development',
        desc: 'Are you in search of a website prioritizing marketing, with excellent rankings, swift loading times, enhanced conversion rates, and a modern aesthetic? Click the button below and lets initiate a conversation, Let us take care of the rest. Connect with us!'
    },
    {
        icon: 'ri-google-line',
        title: 'Google Ads',
        desc: 'Elevated intent leads to an increased conversion rate, and that is precisely the focus of Google Ads. By targeting the correct keywords, you connect with the right audience. Lets discuss!'
    },
    {
        icon: 'ri-microsoft-line',
        title: 'Microsoft Ad Network',
        desc: 'While it may not be the largest platform available, it features a distinct audience exclusive to the network, and the additional data layer provided by LinkedIn enhances the appeal.'
    },
    {
        icon: 'ri-google-fill',
        title: 'Google DV 360',
        desc: 'DV360 stands out as the largest and most formidable platform for reaching all the crucial elements. As an enterprise-level tool, it facilitates seamless scalability.'
    },
    {
        icon: 'ri-file-edit-line',
        title: 'Content Management',
        desc: 'We craft compelling content strategies tailored to your audience, driving engagement and fostering brand loyalty. Our approach ensures impactful storytelling, enhanced visibility, and measurable results for sustainable growth.'
    },
    {
        icon: 'ri-user-shared-fill',
        title: 'Social Media Marketing',
        desc: 'We create engaging social media campaigns that captivate your audience, fostering community and driving brand awareness. Our strategies enhance interactions, boost visibility, and deliver tangible results for growth.'
    },
    {
        icon: 'ri-bar-chart-grouped-fill',
        title: 'Business Analysis',
        desc: 'We delve deep into data, assess market trends, and provide actionable insights, empowering you to make informed decisions and drive sustainable growth in todays dynamic business landscape. Lets discuss!'
    },
];

const services = () => {
  return <section id="service">
    <div className="container">
        <div className="services_top-content">
            <h6 className='subtitle'>Our Services</h6>
            <h2 className='title'>What We Offer</h2>
            <h2 className='highlight'>With Our Best Services</h2>
        </div>

        <div className="service_item-wrapper">
            {
                serviceData.map((item, index) => 
                     <div className="services_item" key={index}>
                <span className="service_icon">
                    <i class={item.icon}></i>
                </span>
                <h3 className='service_title'>{item.title}</h3>
                <p className="description">{item.desc}</p>
                </div>
                )
            }
        </div>
    </div>
  </section>
}

export default services
