import React from 'react';
import '../../styles/testimonial.css';
import Slider from 'react-slick';
import ava01 from '../../images/ava-1.jpg';
import ava02 from '../../images/ava-2.jpg';
import ava03 from '../../images/ava-3.jpg';

const Testimonial = () => {

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        switpeToSlide: true,
    };
  return (
    <section>
      <div className="container">
        <div className="slider_content-top">
          <h6 className="subtitle">Testimonial</h6>
          <h2>Trusted by Our Clients</h2>
          <span className="highlight">1000+ Happy Clients</span>
        </div>

        <div className="slider_wrapper">
          <Slider {...settings}>
            <div className="slider_item">
              <p className="description">
              "I am thoroughly impressed with the exceptional services provided by Rexium IT Solutions. Their expertise in digital marketing and content strategy has significantly boosted our brand visibility and engagement. The team was professional, responsive, and dedicated to understanding our unique needs. Thanks to their innovative solutions, we have seen remarkable growth in our online presence. I highly recommend Rexium IT Solutions to any business looking to elevate their digital marketing efforts.I highly recommend their services to any organization seeking top-notch digital marketing solutions."
              </p>
              <div className="customer_details">
                <div className="customer_img">
                  <img src={ava01} alt="customer-img" />
                </div>
                <div>
                  <h5 className="customer_name">Davis M</h5>
                  <p className='description'>Content Manager, CreateC-London</p>
                </div>
              </div>
            </div>

            <div className="slider_item">
              <p className="description">
              "Working with Rexium IT Solutions has been a game-changer for our marketing efforts. Their strategic approach and deep understanding of the digital landscape have driven impressive results for our campaigns. The team's creativity and attention to detail have elevated our brand's presence, resulting in increased engagement and conversions. Rexium IT Solutions's commitment to excellence and their ability to deliver on promises make them a valuable partner. I highly recommend their services to any organization seeking top-notch digital marketing solutions."
              </p>
              <div className="customer_details">
                <div className="customer_img">
                  <img src={ava02} alt="customer-img" />
                </div>
                <div>
                  <h5 className="customer_name">Synthia</h5>
                  <p className='description'>Director Marketing </p>  
                </div>
              </div>
            </div>

            <div className="slider_item">
              <p className="description">
              "Rexium IT Solutions has truly exceeded my expectations with their outstanding services. Their innovative approach and in-depth knowledge of digital marketing have significantly enhanced our brand's online presence. The team was incredibly professional, attentive, and committed to understanding our specific needs. As a result, we've seen substantial growth in engagement and reach. Thanks to their expertise, Mr. Amit was an absolute pleasure to work with. I highly recommend Rexium IT Solutions to anyone looking to elevate their digital marketing strategy."
              </p>
              <div className="customer_details">
                <div className="customer_img">
                  <img src={ava03} alt="customer-img" />
                </div>
                <div>
                  <h5 className="customer_name">Dr.Pranyash Ghosh</h5>
                  <p className='description'>Director, Clovis Dentals </p>  
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
