import React from 'react'
import '../../styles/newsletter.css'

const Newsletter = () => {
  return (
    <section className="section_newsletter">
      <div className="container">
        <div className="newletter_wrapper">
          <div className="newsletter_content">
            <h6 className="subtitle">Let's Work</h6>
            <h2>
            Explore The <span className='highlight'>World Of Idea's </span> 
            <p>and
            Subscribe !</p></h2>
          </div>

          <div className="newsletter_form">
            <input type="email" placeholder="Email"/>
            <button className='secondary_btn'>Subscribe Now </button>
          </div>  
        </div>
      </div>
    </section>
  )
}

export default Newsletter